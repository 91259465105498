import store from "@/store";
import {USER_DATA} from "@/router/api";
import {apiRequest, isLocalhost, navigateTo} from "@/utils/helpers";


export default function authGuard(to, from, next) {
    if (to.meta.isAuth) {

        if (store.state.isAuthenticated && isLocalhost()) {
            return next();
        }

        if (Object.keys(store.state.user).length === 0) {
            apiRequest(USER_DATA, null, 'get')
                .then((result) => {

                    if (result.success && result.result) {
                        store.commit('login', result.result);

                        return next();
                    } else {
                        return navigateTo('login');
                    }
                }).catch((error) => {
                return;
                    navigateTo('login');
                    console.log(error);
            });
        }

        if (store.state.isAuthenticated) {
            return next();
        }

    } else {
        next();
    }
}