<template>
    <div v-html="getIcon()"></div>
</template>

<script>
import {icons} from "@/utils/icons";

export default {
    props: ['iconName', 'colorVar'],
    methods: {
        getIcon() {
            const icon = icons.find(icon => icon.name === this.iconName);
            const svg = icon ? icon.svg : icons.find(icon => icon.name === 'default').svg;

            if(this.colorVar) {
                const color = getComputedStyle(document.documentElement).getPropertyValue(this.colorVar).trim();
                return svg.replace(/fill="[^"]*"/g, `fill="${color}"`);
            }

            return svg;
        }
    }
}
</script>
