<template>
    <div class="bg-white border border-gray-200 rounded-2xl shadow-base p-6 flex flex-col h-full">
        <div class="mt-5 block sm:flex">
            <div class="flex">
                <icon icon-name="calendar"/>
                <span class="ml-3 text-black">{{ moment(props?.data?.start_at).format('DD.MM.yy') }}</span>
            </div>
            <div class="flex mt-2 sm:mt-0 sm:ml-10">
                <icon icon-name="mapPin"/>
                <span class="ml-2 text-black">{{ props?.data?.place_info }}</span>
            </div>
        </div>

        <div class="mt-5 block sm:flex">
            <div class="w-full sm:w-2/5 mr-5" v-if="props.data.banner_url">
                <img :src="props.data.banner_url" class="rounded-2xl" alt="">
            </div>
            <div class="mt-7 sm:mt-0">
                <div class="text-base font-bold mt-3">
                    {{ props?.data?.direction?.name }}
                </div>
                <div class="text-lg text-base-color font-bold mt-3">
                    {{ props?.data?.title }}
                </div>
            </div>
        </div>

        <div class="block sm:flex sm:justify-between mt-7">
            <div class="w-full mt-4 sm:mt-0 sm:w-1/3">
                <button class="btn header_auth btn_white d_center !text-sm p-3 w-full"
                        @click="router.push({name: 'show-competition', params: {id: props?.data?.uuid}})">
                    Посмотреть
                </button>
            </div>
<!--            <div class="w-full sm:w-1/3" v-if="props?.data?.has_payed_registration">-->
            <div class="w-full sm:w-1/3 sm:ml-3 sm:mr-3 mt-3 sm:mt-0">
                <button class="btn btn_blue w-full !text-sm p-3 d_center"
                        @click="router.push({name: 'sportsman-file-upload', params:
                        {
                            id: props?.data?.uuid,
                            sportsman_registration: true,
                        }})">
                    <icon icon-name="setupWhite" class="mr-3"/>
                    Документы
                </button>
            </div>
<!--            </div>-->
<!--            <div class="w-full sm:w-1/3" v-else>-->
            <div class="w-full sm:w-1/3 mt-3 sm:mt-0">
                <button class="btn btn_blue w-full !text-sm p-3 d_center"
                        @click="router.push({name: 'payment-sportsman',
                        params: {
                            id: props?.data?.uuid,
                            sportsman_registration: true,
                        }})">
                    <icon icon-name="payment" class="mr-3"/>
                    Оплатить
                </button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import moment from 'moment';
import {navigateTo, uuid} from "@/utils/helpers";
import ToggleSwitch from "@/views/components/inputComponent/toggleSwitch.vue";
import Icon from "@/views/components/helpers/icon.vue";
import router from "@/router/router";
import Modal from "@/views/components/modal/modal.vue";
import useEmitter from "@/utils/useEmitter";
import CreatedAt from "@/views/components/competitions/createdAt.vue";
import store from "@/store";

const emitter = useEmitter();
const eventModal = ref(false);
const props = defineProps({
    data: {
        type: Object,
        default: {}
    },
    pastEvent: {
        type: Boolean,
        default: false
    }
})
const closeModal = () => {
    eventModal.value = false;
};

</script>
