<template>
    <div class="wrap_input">
        <div class="label-personal-page font-bold text-sm flex justify-normal items-center mb-2">Организация*</div>
        <VueMultiselect
            v-model="selected"
            :options="organisations"
            @search-change="handleSearchInput"
            track-by="id"
            label="title"
            deselect-label="Удалить"
            select-label="Выбрать"
            selected-label="Выбрано"
            :allow-empty="false"
            :multiple="true"
            placeholder="Введите название организации"
            :showNoOptions="false"
        >
            <template v-slot:noResult class="no-result">
                <span>Организация не выбрана</span>
            </template>
        </VueMultiselect>

    </div>

</template>

<script setup>
import store from "@/store";
import VueMultiselect from 'vue-multiselect'
import {getOrganisations, searchOrganisations} from "@/utils/helpers";
import {defineProps, onMounted, ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import { debounce } from 'lodash';

const emitter = useEmitter();
const selected = ref(null);
const organisations = ref([]);

const props = defineProps({
    organisations: {
        type: Object,
        default: () => ({})
    }
})
const performSearch = async (term) => {
    try {
        const response = await searchOrganisations(term);
        organisations.value = response ? response.result : []; // обновить список орг ? очистить, если нет результатов
    } catch (error) {
        console.error('Ошибка поиска организаций:', error);
    }
};

const debouncedSearch = debounce((term) => {
    performSearch(term);
}, 300);

const handleSearchInput = (term) => {
    debouncedSearch(term);
};

const updateSelectedFromStore = () => {
    if(!Object.keys(props.organisations).length) {
        selected.value = store.getters.getUserOrganisations;
    }
};

onMounted(async () => {
    await performSearch(''); // поиск с пустым запросом, получить все организации
    updateSelectedFromStore();
});

watch(() => store.getters.getUserOrganisations, (newData, oldData) => {
    if(JSON.stringify(newData) !== JSON.stringify(oldData)) {
        updateSelectedFromStore();
    }
});

watch(() => selected.value, (newData, oldData) => {
    if(JSON.stringify(newData) !== JSON.stringify(oldData)) {
       emitter.emit("organisation", newData);
    }
});

watch(() => props.organisations, (newData, oldData) => {
    if((JSON.stringify(newData) !== JSON.stringify(oldData)) && Object.keys(newData).length) {
        selected.value = newData;
    }
}, {deep: true});

</script>