<template>
    <wrap-section>
        <span class="text-dark-base text-xl font-bold">Роль на платформе</span><br><br>
        <div class="flex mt-4 relative flex-wrap">

            <div class="modal w-full h-full top-0 left-0 flex items-center justify-center absolute transition-opacity duration-300"
                 :class="{ 'opacity-100': modalVisible, 'hidden': !modalVisible, 'opacity-0': !modalVisible }">
                <div class="modal-container bg-white shadow-lg z-50 overflow-y-auto top rounded-2xl">
                    <div class="modal-content py-2 px-2 text-left flex bg-grad-dark w-fit flex-wrap justify-center relative">
                        <div class="absolute top-2 right-2 cursor-pointer">
                            <icon icon-name="closeWhite" class="" @click="openingModalClicked = modalVisible = false"/>
                        </div>

                        <div v-for="role in unRegisterRoles" class="bg-white rounded-2xl m-1 md:m-3 cursor-pointer" @click="newRole(role.type)">
                            <div class="flex justify-center items-center">
                                <img :src="role.icon" alt="" class="img_role">
                            </div>
                            <div class="w-full text-center pb-3 font-bold">
                                {{role.title}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-for="el in store.getters.getUserRoles" v-if="store.getters.getUserRoles.length">
                <div class="d_center flex-col add_sport w-28 h-36 bg-white rounded-2xl mb-5 sm:mb-0 mr-5 overflow-hidden shadow-base relative" v-if="el?.name !== 'super'">
                    <icon icon-name="closeBlueRing" class="absolute top-2 right-2 cursor-pointer" @click="deleteRole(el)"/>
                    <img :src="getRolePhoto(el)" alt="" class="h-20">
                    <div class="text-center font-bold text-black">
                        {{ getRoleTitle(el) ?? el }}
                    </div>
                </div>
            </div>


            <div class="d_center flex-col add_sport cursor-pointer w-28 h-36 bg-light-base rounded-2xl select-none" v-if="unRegisterRoles.length" @click="modalRoles">
                <icon icon-name="plus" />
                <div class="text-center text-base-color select-none">
                    Добавить роль
                </div>
            </div>
        </div>
    </wrap-section>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import Icon from "@/views/components/helpers/icon.vue";
import {roles} from "@/utils/roles";
import {onMounted, onUnmounted, ref, watch} from "vue"
import {apiRequest, getRolePhoto, getRoleTitle} from "@/utils/helpers";
import {ROLES} from "@/router/api";
import {useStore} from "vuex";

const store = useStore();

let modalVisible = ref(false);
let openingModalClicked = ref(false);

let deleteRole = (role) => {
    apiRequest(ROLES, {role: role}, 'delete')
        .catch(e => {
            console.log('error', e)})
        .then(resp => {
            if(resp && resp.success) {
                store.commit('rolesUpdate', resp);
            }
        })
}

const unRegisterRoles = ref([]);
const currentRoleNames = () => {
    console.log('store.getters', store.getters);
    return store.getters.getUserRoles.map(item => item);
};

const updateUnRegisterRoles = () => {
    let tmp = currentRoleNames();
    if(tmp && tmp.length) {
        unRegisterRoles.value = roles.filter(item => !tmp.includes(item.type) && item.type !== 'organizer' );
    } else if(!tmp.length) {
        unRegisterRoles.value = roles;
    }
};

updateUnRegisterRoles();

watch(() => store.getters.getUserRoles, () => {
    updateUnRegisterRoles();
});

const modalRoles = () => {
    modalVisible.value = !modalVisible.value;
    openingModalClicked.value = true;
}

const newRole = (role) => {

    apiRequest(ROLES, {role: role}, 'put')
        .catch(e => {
            console.log('error', e)})
        .then(resp => {
            if(resp && resp.success) {
                store.commit('rolesUpdate', resp);
                modalVisible.value = false;
            }
        })
}

const handleOutsideClick = (event) => {
    const modalContainer = document.querySelector(".modal-container");
    if (
        modalVisible.value &&
        !openingModalClicked.value &&
        !modalContainer.contains(event.target) &&
        !event.target.closest(".modal-container")
    ) {
        modalVisible.value = false;
    }
    openingModalClicked.value = false;
};

onMounted(() => {
    document.addEventListener("click", handleOutsideClick);
});

onUnmounted(() => {
    document.removeEventListener("click", handleOutsideClick);
});


</script>

<style scoped>
.add_sport {
    border: 1px solid #DDE1E7;
}
.img_role {
    width: 80%;
}

.modal {
    top: -130%;
    left: 0;
}
@media (max-width: 639px) {
    .img_role {
        width: 60%;
    }
}


</style>
