<template>
    <div class="preloader_body">
        <div class="preloader_item">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>
  
<style scoped>
.preloader_body {
    position: absolute
}

.preloader_item {
    height: 30px;
    position: relative;
    width: 30px;
}
.preloader_item > div {
    background-color: rgba(255, 255, 255, 0);
    height: 10px;
    position: absolute;
    width: 4px;

    /* css3 radius */
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;

    /* css3 transform - scale */
    -webkit-transform: scale(0.4);
    -moz-transform: scale(0.4);
    -o-transform: scale(0.4);
    transform: scale(0.4);

    /* css3 animation */
    -webkit-animation-name: preloader_item;
    -webkit-animation-duration: 1.04s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: linear;
    -moz-animation-name: preloader_item;
    -moz-animation-duration: 1.04s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-direction: linear;
    -o-animation-name: preloader_item;
    -o-animation-duration: 1.04s;
    -o-animation-iteration-count: infinite;
    -o-animation-direction: linear;
    animation-name: preloader_item;
    animation-duration: 1.04s;
    animation-iteration-count: infinite;
    animation-direction: linear;
}

.preloader_item > div:nth-child(1) {
    left: 0;
    top: 12px;

    /* css3 transform - rotate */
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);

    /* css3 animation */
    -webkit-animation-delay: 0.39s;
    -moz-animation-delay: 0.39s;
    -o-animation-delay: 0.39s;
    animation-delay: 0.39s;
}

.preloader_item > div:nth-child(2) {
    left: 3px;
    top: 4px;

    /* css3 transform - rotate */
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);

    /* css3 animation */
    -webkit-animation-delay: 0.52s;
    -moz-animation-delay: 0.52s;
    -o-animation-delay: 0.52s;
    animation-delay: 0.52s;
}

.preloader_item > div:nth-child(3) {
    left: 12px;
    top: 1px;

    /* css3 transform - rotate */
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);

    /* css3 animation */
    -webkit-animation-delay: 0.65s;
    -moz-animation-delay: 0.65s;
    -o-animation-delay: 0.65s;
    animation-delay: 0.65s;
}

.preloader_item > div:nth-child(4) {
    right: 3px;
    top: 4px;

    /* css3 transform - rotate */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);

    /* css3 animation */
    -webkit-animation-delay: 0.78s;
    -moz-animation-delay: 0.78s;
    -o-animation-delay: 0.78s;
    animation-delay: 0.78s;
}

.preloader_item > div:nth-child(5) {
    right: 0;
    top: 12px;

    /* css3 transform - rotate */
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);

    /* css3 animation */
    -webkit-animation-delay: 0.91s;
    -moz-animation-delay: 0.91s;
    -o-animation-delay: 0.91s;
    animation-delay: 0.91s;
}

.preloader_item > div:nth-child(6) {
    right: 4px;
    bottom: -2px;

    /* css3 transform - rotate */
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);

    /* css3 animation */
    -webkit-animation-delay: 1.04s;
    -moz-animation-delay: 1.04s;
    -o-animation-delay: 1.04s;
    animation-delay: 1.04s;
}

.preloader_item > div:nth-child(7) {
    bottom: -4px;
    left: 12px;

    /* css3 transform - rotate */
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);

    /* css3 animation */
    -webkit-animation-delay: 1.17s;
    -moz-animation-delay: 1.17s;
    -o-animation-delay: 1.17s;
    animation-delay: 1.17s;
}

.preloader_item > div:nth-child(8) {
    left: 2px;
    bottom: 0;

    /* css3 transform - rotate */
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);

    /* css3 animation */
    -webkit-animation-delay: 1.3s;
    -moz-animation-delay: 1.3s;
    -o-animation-delay: 1.3s;
    animation-delay: 1.3s;
}

/* css3 keyframes - preloader_item */
@-webkit-keyframes preloader_item {
    0% { background-color: #0FA1C1; }
    100% { background-color: rgba(255, 255, 255, 0); }
}
@-moz-keyframes preloader_item {
    0% { background-color: #0FA1C1; }
    100% { background-color: rgba(255, 255, 255, 0); }
}
@-o-keyframes preloader_item {
    0% { background-color: #0FA1C1; }
    100% { background-color: rgba(255, 255, 255, 0); }
}
@keyframes preloader_item {
    0% { background-color: #0FA1C1; }
    100% { background-color: rgba(255, 255, 255, 0); }
}
</style>
