<template>
    <div>
        <span class="ml-2 d_center" v-if="store.state.userStatus === 'active'">
            <icon icon-name="authorised" @click="openModal" class="cursor-pointer"/>
        </span>
        <span class="ml-2 d_center" v-else-if="store.state.userStatus === 'moderating'">
            <icon icon-name="moderating" @click="openModal" class="cursor-pointer"/>
        </span>
        <span class="ml-2 d_center" v-else>
            <icon icon-name="attention" @click="openModal" class="cursor-pointer"/>
        </span>

        <Modal :visible="showModal.draft">
            <div class="text-left text-base-color font-bold text-lg">Аккаунт не верифицирован</div>
            <div class="text-sm mt-5">Профиль не подтвержден, перейдите в редактирование профиля, чтобы загрузить
                документы для верификации аккаунта.
            </div>
            <button class="btn btn_blue w-full mt-5" @click="profileUpdate">Редактировать профиль</button>
        </Modal>
        <Modal :visible="showModal.rejected">
            <div class="text-left text-danger-dark font-bold text-lg">Аккаунт не прошел модерацию</div>
            <div v-for="el in store.state?.user?.validation_comments" class="mt-5 text-sm">
                <div class="text-left">{{el.updated_at ? moment(el.updated_at).format('DD.MM.yy') : null}}</div>
                <div class="text-left font-bold">{{el.message}}</div>
            </div>
            <button class="btn btn_blue w-full mt-5" @click="profileUpdate">Редактировать профиль</button>
        </Modal>
        <Modal :visible="showModal.active">
            <div class="text-left text-base-color font-bold text-lg">Аккаунт верифицирован</div>
            <div class="text-sm mt-5">Ваш профиль пользователя подтвержден.</div>
            <button class="btn btn_blue w-full mt-5" @click="closeModal">Понятно</button>
        </Modal>
        <Modal :visible="showModal.moderating">
            <div class="text-left text-base-color font-bold text-lg">Аккаунт на модерации</div>
            <div class="text-sm mt-5">Ваш профиль в настоящий момент модерируется</div>
            <button class="btn btn_blue w-full mt-5" @click="closeModal">Понятно</button>
        </Modal>
    </div>
</template>

<script setup>
import Modal from "@/views/components/modal/modal.vue";
import { ref } from "vue";
import { navigateTo } from "@/utils/helpers";
import useEmitter from "@/utils/useEmitter";
import store from "@/store";
import Icon from "@/views/components/helpers/icon.vue";
import moment from "moment/moment";

const emitter = useEmitter();

const showModal = ref({
    active: false,
    moderating: false,
    rejected: false,
    draft: false
});
const openModal = () => {
    if(store.state.userStatus === 'active') {
        showModal.value.active = true;
    } else if (store.state.userStatus === 'moderating') {
        showModal.value.moderating = true;
    } else if (store.state.userStatus === 'rejected') {
        showModal.value.rejected = true;
    } else {
        showModal.value.draft = true;
    }
};

const profileUpdate = () => {
    document.documentElement.classList.remove('overflow-hidden');
    navigateTo('profile')
}

emitter.on('closeModal', () => {closeModal();})

const closeModal = () => {
    showModal.value.active = false;
    showModal.value.moderating = false;
    showModal.value.rejected = false;
    showModal.value.draft = false;
};
</script>
