export const eChampRoles = [
    {
        'icon': '/img/registration/2.webp', 'title': 'E-champ тренер', 'type': 'e_champ_trainer',
        'info': 'После регистрации информация вашего профиля с E-champ будет перенесена, включая результаты и связь с аккаунтами спортсменов. <br />Вы сможете участвовать в соревнованиях, регистрировать спортсменов, оплачивать участие и отслеживать результаты.',
        'btn': 'Зарегистрироваться', 'urlType': 'e-champ-trainer'
    },
    // {
    //     'icon': '/img/registration/3.webp', 'title': 'E-champ Спортсмен', 'type': 'e_champ_sportsman',
    //     'info': 'Какой инфо текст?',
    //     'btn': 'Зарегистрироваться', 'urlType': 'e-champ-sportsman'
    // }
];