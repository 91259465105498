<template>
    <baseLayout>

        <div class="wrap_login d_center">
            <div class="login_form">
                <button class="btn header_auth btn_white d_center" @click="navigateTo('login')">
                    <icon icon-name="back" class="mr-7"/> Назад
                </button>
                <h1 class="text-left mt-7">Сброс пароля</h1>
                <div class="mt-7"></div>

                <input-custom label="Новый пароль" type="password" v-model="fd.password" class="mt-4 mob_margin"
                              labelClass="new-pass-update"/>
                <input-custom label="Повторите пароль" type="password" v-model="fd.passwordRepeat"
                              class="mt-4 mob_margin" labelClass="new-pass-update"/>

                <alerts alertType="resetPassword"/>

                <button class="btn mt-7 btn_blue w-full" @click="loginUser">
                    Сбросить пароль
                </button>
            </div>
        </div>
    </baseLayout>
</template>

<script setup>
import baseLayout from "@/views/layout/base";
import {FILES, FORGOT_PASSWORD, LOGIN, RESET_PASSWORD, SANCTUM, USER_DATA} from "@/router/api";
import axios from "axios";
import Alerts from "@/views/components/helpers/alerts.vue";
import {ref} from "vue";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import useEmitter from "@/utils/useEmitter";
import Icon from "@/views/components/helpers/icon.vue";
import {apiRequest, navigateTo} from "@/utils/helpers";
import store from "@/store";
import {useRoute} from "vue-router";

const axiosConfig = {
    withCredentials: true,
    withXSRFToken: true,
    headers: {
        "Accept": "application/json"
    }
};
const route = useRoute();
axios.defaults = Object.assign(axios.defaults, axiosConfig);

const fd = ref({
    token: route.params.id,
    email: route.query.email,
    passwordRepeat: '',
    password: '',
});
const emitter = useEmitter();

const verify = () => {
    if (fd.value.password.length < 8) {
        emitter.emit("alert", {alertType: 'resetPassword', message: "Пароль не может быть короче 8 символов"})
        return false;
    }
    if (fd.value.passwordRepeat !== fd.value.password) {
        emitter.emit("alert", {alertType: 'resetPassword', message: "Пароли не совпадают"})
        return false;
    }
    return true;
};
const loginUser = async () => {

    if (verify()) {
        await apiRequest(RESET_PASSWORD, fd.value, 'post')
            .catch(e => {
                emitter.emit("alert", {alertType: 'resetPassword', message: e.message})
                return console.log("ERROR", e);
            })
            .then(async resp => {
                if (resp && resp.success) {
                    fd.value.password = '';
                    fd.value.passwordRepeat = '';
                    emitter.emit("success", {alertType: 'resetPassword', message: "Данные успешно обновлены"});
                }
            })
    }

};

</script>

<style scoped>
.header_auth {
    line-height: 40px;
    width: 170px;
    height: 40px;
}

.header_auth svg {
    margin-right: 30px;
}

.sub_title {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.forgot_pass a {
    margin-top: 15px;
    color: var(--base-color);
    text-align: right;
    display: flex;
    justify-content: right;

}

.wrap_login {
    width: 100%;
    margin-top: 100px;
}

.login_form {
    background-color: var(--white);
    width: 600px;
    min-height: 150px;
    border: 1px solid var(--dark-border);
    padding: 50px;
    border-radius: 30px;
    box-shadow: 0px 10px 20px 0px rgba(25, 111, 146, 0.10);
}

@media (max-width: 639px) {
    .login_form {
        margin-top: 70px;
    }

    .wrap_login {
        width: 90%;
        margin: 0 auto;
    }
}

@media (max-width: 576px) {
    .mt-4 {
        margin-top: 30px;
    }

    .login_form {
        padding: 30px 20px;
    }

    .header_auth {
        font-size: 16px;
        text-align: center;
        line-height: 40px;
        width: 120px;
    }

    .header_auth img {
        margin-right: 20px;
    }

    .sub_title {
        font-size: 16px;
        text-align: center;
    }
}

@media (max-width: 476px) {
    .mob_margin {
        margin-top: unset;
    }
}

</style>
