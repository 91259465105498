<template>
    <label :for="id" class="flex items-center cursor-pointer">
      <input
        type="radio"
        :id="id"
        :value="value"
        :checked="modelValue === value" 
        @change="updateValue"
        class="hidden peer"
      />
      <span class="w-7 h-7 border border-solid border-light-border bg-sky-blue rounded-full transition duration-150 ease-in-out relative flex items-center justify-center cursor-pointer">
        <span
        :class="modelValue === value ? 'bg-base-color' : 'bg-sky-blue'"
        class="w-4 h-4 rounded-full peer-checked:block"></span>
      </span>
      <span class="ml-2 text-black font-inter text-base font-normal leading-4">{{ label }}</span>
    </label>
  </template>
  
  <script setup>
  import { defineProps, defineEmits } from 'vue';
  
  const props = defineProps({
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  });
  
  const emit = defineEmits(['update:modelValue']);
  
  const updateValue = () => {
    emit('update:modelValue', props.value); // Эмитируем новое значение при изменении
  };
  </script>