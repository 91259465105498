<template>
    <div class="mt-7 mb-7 flex justify-between">
        <base-user-info :data="data"/>
        <div class="flex-auto text-right relative">
            <span v-if="is_represent">
                <span class="text-xs text-green-message-text bg-green-message p-2.5 rounded-2xl hidden md:inline">Добавлено</span>
                <icon icon-name="green_circle_plus" class="inline-block md:hidden"/>
            </span>

               <button @click="addRepresent" v-else :disabled="isLoading"
                       :class="{ 'hidden': isLoading, 'md:inline': !isLoading }" v-if="data.status === 'active'">
                    <icon icon-name="plus" class="hidden -mb-0.5 mr-2 md:inline-block"/>
                    <span class="text-sm text-base-color hidden md:inline">Добавить</span>
                    <icon icon-name="blue_circle_plus" class="inline-block md:hidden"/>
                </button>


            <div v-if="isLoading" class="loader inline-block ml-1"></div>
        </div>
    </div>

</template>

<script setup>
import {defineProps, onMounted, ref} from "vue";
import BaseUserInfo from "@/views/components/profile/baseUserInfo.vue";
import icon from "@/views/components/helpers/icon.vue";
import {apiRequest} from "@/utils/helpers";
import {REPRESENT} from "@/router/api";
import useEmitter from "@/utils/useEmitter";
import {useRoute} from "vue-router";

const emitter = useEmitter();
const isLoading = ref(false);
const is_represent = ref(false);
const route = useRoute();
const props = defineProps({
    data: Object,
});

const fd = ref({
    user_id: '',
    event_id: route.params.id,
});

onMounted(() => {
    is_represent.value = props.data.is_represent;
    fd.value.user_id = props.data.id;
});

const addRepresent = () => {
    if (isLoading.value) return;
    isLoading.value = true;
    apiRequest(REPRESENT, fd.value, 'post')
        .then(resp => {
            if (resp && resp.success) {
                is_represent.value = true;
                emitter.emit('represent_update');
            }
        })
        .catch(e => {
            console.log('error', e)
        })
        .finally(() => {
            isLoading.value = false;
        });
}


</script>