export const roles = [
    {
        'icon': '/img/registration/1.webp',
        'title': 'Организатор', 'type': 'organizer',
        'info': 'Организаторы смогут создавать график соревнований, отслеживать все этапы подготовки и проведения мероприятий, а также контролировать бюджет и расходы.',
        'btn': 'Подать заявку',
        'urlType': 'trainer'
    },
    {
        'icon': '/img/registration/2.webp', 'title': 'Тренер', 'type': 'trainer',
        'info': 'Зарегистрировавшись, вы сможете добавить зарегистрированных спортсменов или создать их профили. <br />Тренер получает возможность участвовать в соревнованиях, регистрировать своих спортсменов, оплачивать участие и следить за их успехами.',
        'btn': 'Зарегистрироваться', 'urlType': 'trainer'
    },
    {
        'icon': '/img/registration/3.webp', 'title': 'Спортсмен', 'type': 'sportsman',
        'info': 'После регистрации вы сможете добавить своего тренера, участвовать в соревнованиях, оплачивать участие и отслеживать свои результаты.',
        'btn': 'Зарегистрироваться', 'urlType': 'sportsman'
    },
    // {
    //     'icon': '/img/registration/4.webp', 'title': 'Родитель', 'type': 'parent',
    //     'info': 'Организаторы смогут создавать график соревнований, отслеживать все этапы подготовки и проведения мероприятий, а также контролировать бюджет и расходы. ',
    //     'btn': 'Зарегистрироваться', 'urlType': 'parent'
    // },
    // {
    //     'icon': '/img/registration/5.webp', 'title': 'Судья', 'type': 'arbiter',
    //     'info': 'Организаторы смогут создавать график соревнований, отслеживать все этапы подготовки и проведения мероприятий, а также контролировать бюджет и расходы. ',
    //     'btn': 'Зарегистрироваться', 'urlType': 'trainer'
    // },
];