<script setup>
  import { ref, onMounted, defineProps, reactive } from 'vue';
  import useEmitter from "@/utils/useEmitter";
  const props = defineProps([ 'customClass' ]);
  let tabContainer = ref(null);
  let tabHeaders = ref(null);
  let tabs = ref(null);
  let activeTabIndex = ref(0);
  const emitter = useEmitter();

  onMounted(() => {
    tabs.value = [ ...tabContainer.value.querySelectorAll('.tab') ];
		for(let x of tabs.value) {
	    if(x.classList.contains('block')) {
				activeTabIndex = tabs.value.indexOf(x);
			}
    }
  })
  const changeTab = (index) => {
    activeTabIndex = index;
    for(let x of [...tabs.value]) {
   		x.classList.remove('block')
   		x.classList.add('hidden')
    }
    for(let x of [...tabHeaders.value]) {
   		x.classList.remove('border-solid', 'border-b-2', 'border-base-color')
    }
		tabs.value[activeTabIndex].classList.add('block')
		tabs.value[activeTabIndex].classList.remove('hidden')
		tabHeaders.value[activeTabIndex].classList.add('border-solid', 'border-b-2', 'border-base-color')
    emitter.emit('tabIndex', activeTabIndex)
  }
</script>

<template>
  <div id="tabs-container" class="py-5" :class="customClass" ref="tabContainer">
    <div id="tab-headers">
      <ul class="flex border-b border-dark-border border-solid">
        <!-- все тайтлы --> 
        <li class="w-2/4 text-center py-2.5 text-dark-base cursor-pointer"
            v-for="(tab, index) in $slots.default()"
            :class="activeTabIndex === index ? 'border-b-2 border-base-color border-solid' : ''"
            @click="changeTab(index)" :tabIndex="index" ref="tabHeaders">
            {{ tab.props.title }} <span v-if="tab.props.total" class="ml-5 text-icon-base">{{ tab.props.total }}</span>
        </li>
      </ul>
    </div>
    <!-- здесь табы -->
    <div id="active-tab">
    	<slot></slot>
    </div>
  </div>
</template>