<template>
    <div class="relative flex items-center"
    :class="[width]"
    >
        <input :value="modelValue"
               @input="updateModelValue"
               :disabled="disabled"
               type="text"
               class="text-sm border rounded-2xl p-4 border-dark-border focus:outline-none focus:border focus:border-base-color focus:shadow-base w-full"
               :class="[height]"
               :placeholder="label"/>

        <div class="absolute right-4 transform -translate-x-1/2">
            <img src="data:image/svg+xml;charset=UTF-8,%3csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.828 15.4966L9.21848 10.8648C8.20348 11.6265 6.97128 12.0394 5.70443 12.0424H5.70318L5.63604 12.043C4.14127 12.043 2.70772 11.4463 1.65076 10.3843C0.593795 9.32221 0 7.88173 0 6.37973L0.000621736 6.30851V6.31226C0.000621736 4.77166 0.609679 3.29416 1.69381 2.20479C2.77794 1.11542 4.24833 0.503418 5.78152 0.503418C7.31471 0.503418 8.78511 1.11542 9.86924 2.20479C10.9534 3.29416 11.5624 4.77166 11.5624 6.31226C11.5464 7.5907 11.1324 8.83201 10.3786 9.86204L10.3905 9.84517L15 14.477L13.828 15.4966ZM5.78152 2.07277C4.66946 2.00845 3.5775 2.39048 2.74566 3.13488C1.91382 3.87928 1.41019 4.92513 1.34548 6.04252C1.28077 7.15992 1.66028 8.2574 2.40057 9.09373C3.14087 9.93006 4.18137 10.4368 5.29334 10.5025C6.40532 10.5682 7.49777 10.1876 8.33054 9.44424C9.16331 8.70089 9.66825 7.65568 9.73436 6.53837C9.80047 5.42106 9.42234 4.3231 8.68309 3.48583C7.94385 2.64857 6.90399 2.14053 5.79209 2.07339H5.78152H5.78214L5.78152 2.07277Z' fill='%23BCCFDE'/%3e%3c/svg%3e" 
                alt="Search Icon" 
            />
        </div>
    </div>
</template>

<script setup>
import { defineProps, withModifiers, defineEmits } from "vue";

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: 'Поиск спортсменов',
    },
    disabled: {
        default: false,
        type: Boolean,
    },
    height: {
        default: 'h-11',
        type: String,
    },
    width: {
        default: 'w-full',
        type: String,
    },
});

const emits = defineEmits(['update:modelValue']);

const updateModelValue = withModifiers((event) => {
    emits('update:modelValue', event.target.value);
}, ['prevent']);
</script>