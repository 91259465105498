<template>
    <div class="wrap_input">
        <div class="wrap_label label-personal-page font-bold text-sm mb-2">Город*</div>
        <VueMultiselect
            v-model="selected"
            :options="options"
            @search-change="handleInput"
            track-by="city"
            label="city"
            deselect-label="Удалить"
            select-label="Выбрать"
            selected-label="Выбрано"
            :allow-empty="false"
            :multiple="false"
            placeholder="Введите город"
            :showNoOptions="false"
        >
            <template v-slot:noResult class="no-result">
                <span>Город не найден</span>
            </template>
        </VueMultiselect>

    </div>

</template>

<script setup>
import VueMultiselect from 'vue-multiselect'
import {ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";

const emitter = useEmitter();
const url   = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address";
const token = "1b0deffc7e91795b4e6b8670be21ad3f3fc48746";

const props = defineProps({
    address: {
        type: Object,
        default: false,
    }
})

let options  = ref([
    {city: "Москва", country: 'Россия', region: 'Москва'},
    {city: "Санк-Петербург", country: 'Россия', region: 'Санк-Петербург'},
    {city: "Новосибирск", country: 'Россия', region: 'Новосибирск'},
    {city: "Екатеринбург", country: 'Россия', region: 'Екатеринбург'},
    {city: "Нижний Новгород", country: 'Россия', region: 'Нижний Новгород'},
]);
let selected = ref(props.address.city ? props.address : null);

watch(() => props.address, (newValue) => {
    if(newValue.city) {
        selected.value = newValue;
    }
});

let prepareSuggestions = (data) => {
    if(data.suggestions.length) {
        const result = data.suggestions.map(suggestion => ({
            country: suggestion.data.country,
            region: suggestion.data.region,
            city: suggestion.data.city
        }));
        options.value = [];
        result.forEach(el => {options.value.push(el)});
    }
}

let handleInput = (value) => {
    fetch(url, dadataOptions(value, token))
        .then(response => response.text())
        .then(result => prepareSuggestions(JSON.parse(result)))
        .catch(error => console.log("error", error));
}

let dadataOptions = (query, token) => {
    return {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token " + token
        },

        body: JSON.stringify({
            query: query,
            from_bound: { "value": "city" },
            to_bound: { "value": "city" },
        })
    }
}

watch(() => selected.value, (newValue) => {
    emitter.emit("address", newValue);
});

</script>

<style>
.wrap_input {
    display: flex;
}
.wrap_label {
    line-height: 45px;
    display: inline-block;
    font-size: 14px;
}
.pass-update {
    flex: 0 0 130px;
}
.multiselect__tags {
    padding: 12px 40px 0 15px;
    background: var(--light-base);
    font-size: 14px;
}
.multiselect__input, .multiselect__single {
    background: var(--light-base);
}
.multiselect, .multiselect__input, .multiselect__single {
    font-size: 14px!important;
}

@media (max-width: 576px) {
    .wrap_input {
        display: block;
    }
    input {
        padding-right: 10%;
    }
    .wrap_label {
        line-height: 25px;
    }
}

</style>

