<template>
    <div class="w-full p-4 bg-green-message rounded-2xl" v-if="isValidate">
        <div class="text-center text-sm">
            Данные подтверждены или на модерации. Заметили ошибку? Напишите в <a href="">службу поддержки</a>
        </div>
    </div>

    <input-custom label="Имя*" type="text" v-model="fd.name" class="mt-4 mob_margin" labelClass="pass-update"
                  :disabled="isValidate"/>
    <input-custom label="Отчество*" type="text" v-model="fd.middle_name" class="mt-4 mob_margin"
                  labelClass="pass-update" :disabled="isValidate"/>
    <input-custom label="Фамилия*" type="text" v-model="fd.surname" class="mt-4 mob_margin" labelClass="pass-update"
                  :disabled="isValidate"/>

    <div class="wrap_age_gender mt-4">
        <date-picker v-model="fd.birthday" label="Дата рождения*" label-class="w-32 font-bold" :disabled="isValidate"/>
        <div class="mt-4 gender sm:mt-0 flex justify-center">
            <gender v-model="fd.sex" :disabled="isValidate" label-class="font-bold"/>
        </div>
    </div>
    <div class="mt-4 mob_margin">
        <dadata-city :address="fd.address"/>
    </div>

    <div class="mt-8" v-if="!isValidate">

        <div v-if="isEditSportsman">
            <upload-personal-doc :data="sportsman_personal_doc"/>
        </div>
        <div v-else>
            <div v-if="!store.state.personalDoc?.length">
                <div class="text-base font-bold">Загрузите документ для подтверждения личности*</div>
                <div class="text-base mb-2">Паспорт или свидетельство о рождении в форматах JPG, PNG</div>
                <file-upload uploadType="personalDoc" message="Ошибка при загрузке Документа удостоверяющего личность"/>
                <div class="text-xs mt-2">*Документ после проверки будет удален с платформы</div>
            </div>

            <div class="flex justify-between" v-else>
                <div>
                    Вы загрузили документ <a :href="store.state.personalDoc" target="_blank" class="text-base-color">удостоверящий
                    личность</a>
                </div>
                <div class="cursor-pointer" @click="deletePersonalDoc">
                    <icon icon-name="delete"></icon>
                </div>
            </div>
        </div>
    </div>

    <div class="mt-8 mob_margin w-full">
        <organisations :organisations="user_organisations"/>
        <div v-if="store.state?.permissions?.includes('public_create_organisation')"
             class="w-full flex mt-3 text-base-color cursor-pointer justify-end"
             @click="addOrganisation = !addOrganisation">
            <icon icon-name="plus" class="mr-3"/>
            Добавить организацию
        </div>
        <div class="w-full mt-3 text-base-color cursor-pointer flex" v-if="addOrganisation">
            <div class="w-full">
                <input-custom label="Название организации*" type="text" v-model="fd.organisationAddName"
                              class="mt-4 mob_margin" labelClass="pass-update-org"/>
                <input-custom label="ИНН*" type="number" v-model="fd.organisationAddInn" class="mt-4 mob_margin"
                              labelClass="pass-update"/>
            </div>
            <div class="p-4 h-full relative" hidden>
                <icon icon-name="delete" class="absolute top-12 sm:top-6"/>
            </div>

        </div>
    </div>

    <alerts alertType="personalData"/>

    <div class="sm:flex sm:justify-between sm:mt-0 mt-3">
        <button class="btn mt-8 btn_blue w-full sm:w-64 " @click="savePersonal">
            Сохранить
        </button>

        <button class="btn mt-8 btn_blue w-full sm:w-64 "
                @click="sentToModeration"
                v-if="!isValidate">
            Отправить на модерацию
        </button>
    </div>


</template>

<script setup>
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import dadataCity from "@/views/components/dashboard/dadataCity.vue";
import {defineProps, onMounted, ref, watch} from "vue";
import Gender from "@/views/components/inputComponent/gender.vue";
import DatePicker from "@/views/components/inputComponent/datePicker.vue";
import FileUpload from "@/views/components/dashboard/fileUpload.vue";
import store from "@/store";
import useEmitter from "@/utils/useEmitter";
import Organisations from "@/views/components/dashboard/organisations.vue";
import Icon from "@/views/components/helpers/icon.vue";
import Alerts from "@/views/components/helpers/alerts.vue";
import {apiRequest, personalDataUpdate} from "@/utils/helpers";
import {FILES, USER_DATA} from "@/router/api";
import {useRoute} from "vue-router";
import UploadPersonalDoc from "@/views/components/dashboard/uploadPersonalDoc.vue";

const props = defineProps({
    type: {
        type: String,
        default: false
    },
    data: {
        type: Object,
        default: () => ({})
    }
})
const route = useRoute();
const emitter = useEmitter();
const addOrganisation = ref(false);
const isValidate = ref(false);
const isEditSportsman = !!route.params.id;

onMounted(async () => {
    if (props.type === 'sportsman') {

    } else {
        const personalData = store.getters.getUserInfo;
        isValidate.value = store.state.userStatus === 'active' || store.state.userStatus === 'moderating';
        fd.value = {...personalData};
        fd.value.address = {
            "city": personalData.location.city,
            "country": personalData.location.country,
            "region": personalData.location.region
        };
    }

});

const fd = ref({
    name: '',
    middle_name: '',
    surname: '',
    birthday: '',
    sex: '',
    address: {},
    organisations: {},
    organisation_ids: [],
    organisationAddName: '',
    organisationAddInn: '',
    moderation: false,
    personal_doc: false,
    status: 'draft',
});
let user_organisations = ref([]);
let sportsman_personal_doc = ref(false);

watch(() => store.getters.isValidate, newValue => {
    isValidate.value = newValue;
});
watch(() => props.data, (newData, oldData) => {
    if(props.data.avatar_update) return ;
    fd.value = {...props.data};
    fd.value.address = {
        "city": props.data.location.city,
        "country": props.data.location.country,
        "region": props.data.location.region
    };
    user_organisations.value = props.data.organisations;
    isValidate.value             = props.data.status === 'active' || props.data.status === 'moderating';
    sportsman_personal_doc.value = props.data.personal_doc ?? false;
}, {deep: true});

emitter.on('address', value => {
    fd.value.address = value;
});
emitter.on('organisation', value => {
    fd.value.organisations = value;
    user_organisations.value = value;
})
emitter.on('birthday', value => {
    fd.value.birthday = value;
})
emitter.on('personalDocDelete', () => {
    if(isEditSportsman) {
        fd.value.personal_doc = false;
    }
})
emitter.on('returnUrl', value => {
    if(value.uploadType === 'personalDoc' && isEditSportsman) {
        fd.value.personal_doc = value.url;
    }
})

const sentToModeration = async () => {
    if (validation()) {

        if ((!store.state.personalDoc?.length && !isEditSportsman)
            || (isEditSportsman && !fd.value.personal_doc)) {
            return emitter.emit("alert", {
                alertType: 'personalData',
                message: 'Загрузите документ удостоверяющий личность ' + (isEditSportsman ? "спортсмена" : ''),
            });
        }
        fd.value.moderation = true;
        await savePersonal();
    }
}


const validation = () => {
    let base = 'Проверьте корректность ввода поля '
    let _base = 'Заполните поле '
    if (fd.value.name.length < 2) return emitter.emit("alert", {alertType: 'personalData', message: base + 'имя'});
    if (fd.value.surname.length < 3) return emitter.emit("alert", {
        alertType: 'personalData',
        message: base + "фамилия"
    });
    if (!fd.value.middle_name || fd.value.middle_name.length < 3) return emitter.emit("alert", {
        alertType: 'personalData',
        message: base + "отчество"
    });

    if (!fd.value.birthday || fd.value.birthday.length < 10) return emitter.emit("alert", {
        alertType: 'personalData',
        message: base + "дата рождения"
    });
    if (fd.value.sex.length < 4) return emitter.emit("alert", {alertType: 'personalData', message: base + "пол"})
    if (!fd.value.address.city) return emitter.emit("alert", {alertType: 'personalData', message: _base + "город"})
    if (Object.keys(fd.value.organisations).length === 0 && !isEditSportsman) {
        return emitter.emit("alert", {alertType: 'personalData', message: _base + "организация"})
    }

    return true;
}

const deletePersonalDoc = async () => {
    const formData = ref({
        type: 'personalDoc',
    });
    await apiRequest(FILES, formData.value, 'delete')
        .catch(e => {
            return console.log("ERROR", e);
        })
        .then(async resp => {
            if (resp && resp.success) {
                store.commit('personalDocDelete');
            }
        })
}

const savePersonal = async () => {
    if (validation()) {

        fd.value.organisation_ids = fd.value.organisations?.map(org => org.id);

        await apiRequest(USER_DATA, fd.value, 'put')
            .catch(e => {
                if (e && e.resp?.error) {
                    emitter.emit("alert", {alertType: 'personalData', message: e.resp.error});
                }
                emitter.emit("alert", {alertType: 'personalData', message: "Данные не удалось обновить"});
            })
            .then(async resp => {
                if (resp && resp.error) {
                    emitter.emit("alert", {alertType: 'personalData', message: resp.error});
                }
                if (resp && resp.success) {
                    fd.value.organisationAddName = '';
                    fd.value.organisationAddInn = '';
                    addOrganisation.value = false;

                    if (fd.value.moderation) {
                        emitter.emit("success", {
                            alertType: 'personalData',
                            message: "Профайл успешно отправлен на модерацию"
                        });
                        isValidate.value    = true;
                        fd.value.moderation = false;
                    } else {
                        emitter.emit("success", {alertType: 'personalData', message: "Данные успешно обновлены"});
                    }

                    if (!props.type) {
                        await personalDataUpdate();
                    }

                    return false;
                }
            })
    }
}


</script>