<template>
    <dashboardLayout>
        <navigation-container class="mt-10">
            <wrap-section :is-empty="true">
                <div class="sm:flex">
                    <div class="flex-1 flex items-center">
                        <h2 class="text-4xl">Мои Регистрации</h2>
                    </div>
                </div>

                <wrap-section :is-empty="true">
                    <competitions-card-sportsman v-for="el in upcomingEvents" :data="el" class="mt-5" :sportsman-card="true"/>

                    <pagination
                        :meta="upcomingEvents?.pagination"
                        v-model="upcomingEventsCurrentPage"
                        class="mt-7"
                    />

                </wrap-section>
            </wrap-section>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import dashboardLayout from "@/views/layout/dashboard";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import {apiRequest, uuid} from "@/utils/helpers";
import {EVENTS} from "@/router/api";
import {onMounted, ref, watch} from "vue";
import {useRoute} from 'vue-router';
import Pagination from "@/views/components/helpers/pagination.vue";
import CompetitionsCardSportsman from "@/views/components/competitions/competitionsCardSportsman.vue";

const route = useRoute();
const eventId = ref(false);
const upcomingEvents = ref([]);
const upcomingEventsCurrentPage = ref(1);

const fetchUpcomingEvents = async (page) => {
    try {
        const resp = await apiRequest(EVENTS + '?sportsman_registration=true', null, 'get'); // загружаются только "мои" сореснования self=true
        
        if (resp && resp.success) {
            upcomingEvents.value = resp.result;
        }
    } catch (e) {
        console.error('Ошибка при загрузке предстоящих соревнований:', e);
    }
};

onMounted(() => {
    if (route.query.event_id) {
        eventId.value = route.query.event_id;
        return;
    }
    fetchUpcomingEvents(upcomingEventsCurrentPage.value);
});

watch(upcomingEventsCurrentPage, (newPage) => {
    fetchUpcomingEvents(newPage);
});

</script>
