<template>
    <div class="wrap_input mt-7" v-bind="$attrs">
        <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Программа</div>
        <VueMultiselect
            v-model="fd.program"
            :options="options.program"
            track-by="id"
            label="title"
            deselect-label="Удалить"
            select-label="Выбрать"
            selected-label="Выбрано"
            :multiple="false"
            :placeholder="!isProgramsLoading ? 'Не выбрана' : null"
            :disabled="isProgramsLoading"
        >
        <template v-slot:loading>
            <div class="preloader-container" v-if="isProgramsLoading">
                <Preloader class="-mt-2" />
            </div>
        </template>
        <template v-slot:noResult>
            <span>Программа не выбрана</span>
        </template>
        </VueMultiselect>

    </div>

    <div class="wrap_input mt-5">
        <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Команда</div>
        <VueMultiselect
            v-model="fd.organisation"
            :options="organisationsOptions"
            track-by="id"
            label="title"
            deselect-label="Удалить"
            select-label="Выбрать"
            selected-label="Выбрана"
            :allow-empty="false"
            :multiple="false"
            :showNoOptions="false"
            placeholder="Не выбрана"
            :disabled="organisationsOptions.length === 1"
        >
            <template v-slot:noResult class="no-result">
                <span>Организации не выбраны</span>
            </template>
        </VueMultiselect>
    </div>

    <div class="wrap_input mt-5">
        <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Снаряд</div>
        <VueMultiselect
            v-model="fd.equipment"
            :options="options.equipment"
            track-by="id"
            label="title"
            deselect-label="Удалить"
            select-label="Выбрать"
            selected-label="Выбрано"
            :allow-empty="false"
            :multiple="false"
            :showNoOptions="true"
            :placeholder="isEquipmentsLoaded ? 'Не выбран' : null"
            :disabled="!isEquipmentsLoaded"
        >
            <template v-slot:loading>
                <div class="preloader-container" v-if="isEquipmentsLoading">
                    <Preloader class="-mt-2" />
                </div>
            </template>
            <template v-slot:noResult class="no-result">
                <span>Снаряд не выбран</span>
            </template>
        </VueMultiselect>
    </div>

    <div class="wrap_input mt-5">
        <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Уровень</div>
        <VueMultiselect
            v-model="fd.levels"
            :options="options.levels"
            track-by="id"
            label="title"
            deselect-label="Удалить"
            select-label="Выбрать"
            selected-label="Выбрано"
            :allow-empty="false"
            :multiple="false"
            :showNoOptions="false"
            :placeholder="isLevelsLoaded ? 'Не выбран' : null"
            :disabled="!isLevelsLoaded"
        >
            <template v-slot:loading>
                <div class="preloader-container" v-if="isLevelsLoading">
                    <Preloader class="-mt-2" />
                </div>
            </template>
            <template v-slot:noResult class="no-result">
                <span>Уровень не выбран</span>
            </template>
        </VueMultiselect>
    </div>



    <div class="wrap_input mt-5">
        <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Категория</div>
        <VueMultiselect
            v-model="fd.category"
            :options="options.category"
            track-by="id"
            label="title"
            deselect-label="Удалить"
            select-label="Выбрать"
            selected-label="Выбран"
            :allow-empty="false"
            :multiple="false"
            :showNoOptions="false"
            :placeholder="isDirectionCategoriesLoaded ? 'Не выбран' : null"
            :disabled="!isDirectionCategoriesLoaded"
        >

            <template v-slot:loading>
                <div class="preloader-container" v-if="isDirectionCategoriesLoading">
                    <Preloader class="-mt-2" />
                </div>
            </template>
            <template v-slot:noResult class="no-result">
                <span>Количество выступающих не выбрано</span>
            </template>
        </VueMultiselect>
    </div>

    <div class="wrap_input mt-5">
        <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Возрастная группа</div>
        <VueMultiselect
            v-model="fd.age_groups"
            :options="formattedAgeGroups"
            track-by="id"
            label="label"
            deselect-label="Удалить"
            select-label="Выбрать"
            selected-label="Выбрана"
            :allow-empty="false"
            :multiple="false"
            :showNoOptions="false"
            :placeholder="isAgeGroupsLoaded ? 'Не выбрана' : null"
            :disabled="!isAgeGroupsLoaded"
        >

            <template v-slot:loading>
                <div class="preloader-container" v-if="isAgeGroupsLoading">
                    <Preloader class="-mt-2" />
                </div>
            </template>
            <template v-slot:noResult class="no-result">
                <span>Возрастная группа не выбрана</span>
            </template>
        </VueMultiselect>
    </div>

    <div class="wrap_input mt-5">
        <div class="label-personal-page font-bold text-sm flex justify-normal items-center">Спортсмены</div>
        <VueMultiselect
            v-model="fd.sportsmen"
            :options="options.sportsmen"
            track-by="id"
            label="full_name"
            deselect-label="Удалить"
            select-label="Выбрать"
            selected-label="Выбрано"
            :multiple="true"
            :showNoOptions="false"
            :placeholder="isSportsmenLoaded ? 'Не выбраны' : null"
            :disabled="!isSportsmenLoaded"
            :max="sportsmen_limit"
        >
             <template v-slot:loading>
                <div class="preloader-container" v-if="isSportsmenLoading">
                    <Preloader class="-mt-2" />
                </div>
            </template>
            <template v-slot:noResult class="no-result">
                <span>Спортсмены не выбраны</span>
            </template>
            <!-- v-slot:max почему-то не отрабатывает -->
            <template v-slot:max>
                <span>Количество спортсменов: </span>
            </template>
        </VueMultiselect>
    </div>

    <alerts alertType="regInfo"/>

    <div class="flex flex-col sm:flex-row sm:justify-between mt-7">
        <div class="w-full sm:w-1/3 order-2 sm:order-1 mt-4 sm:mt-0">
            <div>
                <button class="btn header_auth btn_white d_center !text-sm p-3 w-full "
                        @click="router.push({name: 'payment-event', params: {id: route.params.id}})">
                    <icon icon-name="payment" color-var="--base-color" class="mr-3" hover-var="--dark-base"/>
                    Перейти к оплате
                </button>
            </div>

        </div>
        <div class="w-full sm:w-1/3 order-1 sm:order-2">
            <button class="btn btn_blue w-full !text-sm p-3 d_center" @click="newRegistration">
                Сохранить
            </button>
        </div>
    </div>


</template>

<script setup>
import store from "@/store";
import VueMultiselect from 'vue-multiselect'
import {apiRequest} from "@/utils/helpers";
import {onMounted, ref, watch, computed} from "vue";
import useEmitter from "@/utils/useEmitter";
import {PROGRAMS, EQUIPMENTS, LEVELS, DIRECTION_CATEGORIES, AGE_GROUPS, SPORTSMAN, REGISTRATION} from "@/router/api";
import Icon from "@/views/components/helpers/icon.vue";
import Preloader from "@/views/components/helpers/preloader.vue";
import Alerts from "@/views/components/helpers/alerts.vue";
import {useRoute} from "vue-router";
import router from "@/router/router";

const props = defineProps({
    registrations: Array
});
const isProgramsLoading = ref(false);
const isEquipmentsLoading = ref(false);
const isLevelsLoading = ref(false);
const isDirectionCategoriesLoading = ref(false);
const isAgeGroupsLoading = ref(false);
const isSportsmenLoading = ref(false);

const isEquipmentsLoaded = ref(false);
const isLevelsLoaded = ref(false);
const isDirectionCategoriesLoaded = ref(false);
const isAgeGroupsLoaded = ref(false);
const isSportsmenLoaded = ref(false);

const sportsmen_limit = ref(0);

const emit = defineEmits(['update:registrations']);
const route = useRoute();
const emitter = useEmitter();
const program = ref([]);
const fd = ref({
    program: null,
    currentProgramId: '',
    currentDirectionCategoryId: '',
    equipment: null,
    levels: null,
    category: null,
    age_groups: null,
    event_uuid: route.params.id,
    sportsmen: null,
    organisation: null,
})
const options = ref({
    currentProgramId: '',
    currentDirectionCategoryId: '',
    program: [],
    levels: [],
    category: [],
    age_groups: [],
    sportsmen: [],
    equipment: [],
    organisations: [],
});

const validation = () => {
    let base = 'Проверьте корректность ввода поля '
    if(!fd.value.program) return emitter.emit("alert", {alertType: 'regInfo', message: base + 'Категория'});
    if(!fd.value.equipment) return emitter.emit("alert", {alertType: 'regInfo', message: base + 'Снаряд'});
    if(!fd.value.levels) return emitter.emit("alert", {alertType: 'regInfo', message: base + 'Уровень'});
    if(!fd.value.sportsmen.length) return emitter.emit("alert", {alertType: 'regInfo', message: base + 'Спортсмены'});
    if(fd.value.sportsmen.length !== fd.value.category?.sportsmen_count) {
        return emitter.emit("alert", {alertType: 'regInfo', message: 'Проверьте колличество добавленных спортсменов для данной категории'});
    }

    return true;
}

const cleanForm = () => {
    // Сбрасываем значения 
    fd.value.equipment = null;
    fd.value.levels = null;
    fd.value.category = null;
    fd.value.age_groups = null;
    fd.value.sportsmen = null;
    // fd.value.organisation = null;

    // Очищаем опции, чтобы не отображались значения от предыдущей программы
    options.value.equipment = [];
    options.value.levels = [];
    options.value.category = [];
    options.value.age_groups = [];
    options.value.sportsmen = [];
    // options.value.organisations = [];

    // Делаем селекты неактивными, пока не загрузятся новые данные
    isEquipmentsLoaded.value = false;
    isLevelsLoaded.value = false;
    isDirectionCategoriesLoaded.value = false;
    isAgeGroupsLoaded.value = false;
    isSportsmenLoaded.value = false;

}

onMounted(async () => {
    if (organisationsOptions.value.length === 1) {
        fd.value.organisation = organisationsOptions.value[0];
    }
    await getPrograms();
});

// запрашиваем программы
const getPrograms = async () => {
    isProgramsLoading.value = true;
    try {
        const resp = await apiRequest(PROGRAMS, null, 'get');
        if (resp.error) {
            emitter.emit("alert", resp.message);
        }
        if (resp.success && resp.result) {
            program.value = resp.result;
            options.value.program = resp.result;
        }
    } catch (e) {
        console.log('error', e);
    } finally {
        isProgramsLoading.value = false;
    }
};

//запрашиваем снаряды, в зависимости от выбранной программы
const getEquipments = async (programId) => {
    isEquipmentsLoading.value = true;

    try {
        const resp = await apiRequest(`${EQUIPMENTS}?program_id=${programId}`, null, 'get');
        if (resp.error) {
            emitter.emit("alert", resp.message);
        }
        if (resp.success && resp.result) {
            options.value.equipment = resp.result;
        }
    } catch (e) {
        console.log('error', e);
    } finally {
        isEquipmentsLoading.value = false;
        isEquipmentsLoaded.value = true;
    }

};

//запрашиваем уровни, в зависимости от выбранной программы
const getLevels = async (programId) => {
    isLevelsLoading.value = true;

    try {
        const resp = await apiRequest(`${LEVELS}?program_id=${programId}`, null, 'get');
        if (resp.error) {
            emitter.emit("alert", resp.message);
        }
        if (resp.success && resp.result) {
            options.value.levels = resp.result;
        }
    } catch (e) {
        console.log('error', e);
    } finally {
        isLevelsLoading.value = false;
        isLevelsLoaded.value = true;
    }
};

//запрашиваем категории (кол-во выступающих), в зависимости от выбранной программы
const getDirectionCategories = async (programId) => {
    isDirectionCategoriesLoading.value = true;
    try {
        const resp = await apiRequest(`${DIRECTION_CATEGORIES}?program_id=${programId}`, null, 'get');
        if (resp.error) {
            emitter.emit("alert", resp.message);
        }
        if (resp.success && resp.result) {
            options.value.category = resp.result;
        }
    } catch (e) {
        console.log('error', e);
    } finally {
        isDirectionCategoriesLoading.value = false;
        isDirectionCategoriesLoaded.value = true;
    }
};

//запрашиваем возрастные группы, в зависимости от выбранной программы и категории (кол-во выступающих)
const getAgeGroups = async (programId, directionCategoryId) => {
    isAgeGroupsLoading.value = true;

    try {
        const resp = await apiRequest(`${AGE_GROUPS}?program_id=${programId}&direction_category_id=${directionCategoryId}`, null, 'get');
        if (resp.error) {
            emitter.emit("alert", resp.message);
        }
        if (resp.success && resp.result) {
            options.value.age_groups = resp.result;
        }
    } catch (e) {
        console.log('error', e);
    } finally {
        isAgeGroupsLoading.value = false;
        isAgeGroupsLoaded.value = true;
    }
};

//запрос спортсменов, в зависимости от категории (пол) и диапазона возрастов
const getSportsman = async (ageGroupId, directionCategoryId, eventUuid) => {
    isSportsmenLoading.value = true;

    try {
        const resp = await apiRequest(`${SPORTSMAN}?age_group_id=${ageGroupId}&direction_category_id=${directionCategoryId}&event_uuid=${eventUuid}`, null, 'get');
        if (resp.error) {
            emitter.emit("alert", resp.message);
        }
        if (resp.success && resp.result) {
            options.value.sportsmen = resp.result.data;
        }
    } catch (e) {
        console.log('error', e);
    } finally {
        isSportsmenLoading.value = false;
        isSportsmenLoaded.value = true;
    }
};


const newRegistration = async () => {
    await apiRequest(REGISTRATION, fd.value, 'post')
        .catch(e => {
            emit('error:registrations', e);
            return console.log("ERROR", e);
        })
        .then(async resp => {
            if (resp && resp.success && resp.result) {
                emit('update:registrations', resp.result);
            }
        })
        .finally(() => {
            cleanForm();
            fd.value.program = null;
            options.value.currentProgramId = '';
        });
}


// Формат отображения возраста
const formattedAgeGroups = computed(() => {
    return options.value.age_groups.map(group => {
        return {
            ...group,
            label: group.max_age ? `${group.min_age} - ${group.max_age}` : `${group.min_age}+`,
        };
    });
});

// организации из store
const organisationsOptions = computed(() => {
    return store.state.user.organisations || [];
});


watch(() => fd.value.program, (newProgram) => {
    if (newProgram) {
        fd.value.currentProgramId = newProgram.id;
        cleanForm();
        getEquipments(newProgram.id);
        getLevels(newProgram.id);
        getDirectionCategories(newProgram.id);
    } else {
        fd.value.currentProgramId = '';
        options.value.equipment = [];
        options.value.levels = [];
        options.value.category = [];
    }
});


watch(() => fd.value.category, (newDirectionCategory) => {
    if (newDirectionCategory) {
        fd.value.currentDirectionCategoryId = newDirectionCategory.id;

        fd.value.age_groups = null;
        options.value.age_groups = [];
        isAgeGroupsLoaded.value = false;

        fd.value.sportsmen = null;
        options.value.sportsmen = [];
        isSportsmenLoaded.value = false;

        getAgeGroups(fd.value.currentProgramId, newDirectionCategory.id);

        // Ограничени кол-ва спортсменов для селекта, в зависимости от sportsmen_count
        sportsmen_limit.value = newDirectionCategory.sportsmen_count;
    } else {

    }
});

watch(() => fd.value.age_groups, (newAgeGroup) => {
    if (newAgeGroup) {

        fd.value.sportsmen = null;
        options.value.sportsmen = [];
        isSportsmenLoaded.value = false;

        getSportsman(newAgeGroup.id, fd.value.currentDirectionCategoryId, fd.value.event_uuid);
    } else {

    }
});


</script>

<style>
.multiselect__input, .multiselect__tags, .multiselect__single {
    background: var(--white);
}

.multiselect--disabled {
    background: var(--light-border);
    border-radius: 15px;
}

.multiselect__placeholder {
    color: var(--black);
    padding-top: unset;
}


</style>

