<template>
    <thead>
        <tr class="border-b border-solid border-light-border bg-light-base text-xs font-normal leading-tigh">
            <th class="sm:py-2 px-2 sm:px-4 text-left hidden sm:table-cell max-w-9 text-dark-base">Место</th>
            <th class="sm:py-2 px-2 sm:px-4 text-left sm:hidden text-dark-base"></th>
            <th class="py-2 w-2/5 px-4 text-left text-dark-base">ФИО</th>
            <th class="w-1/8 sm:py-2 px-2 sm:px-4 text-left text-dark-base flex-1" @click="sort('score')">
                <span class="flex items-center cursor-pointer">
                    <icon icon-name="sorting" class="mr-1 sm:mr-2"/>
                    Баллы
                </span>
            </th>
            <header-item label-name="Золото"    icon-name-prop="gold"   @click="sort('gold')"/>
            <header-item label-name="Серебро"   icon-name-prop="silver" @click="sort('silver')"/>
            <header-item label-name="Бронза"    icon-name-prop="bronze" @click="sort('bronze')"/>
        </tr>
    </thead>
</template>
<script setup>
import Icon from "@/views/components/helpers/icon.vue";
import headerItem from "@/views/components/raiting/headerItem.vue";
import useEmitter from "@/utils/useEmitter";

const emitter = useEmitter();

const sort = (type) => {
    emitter.emit("sort", type);
}


</script>