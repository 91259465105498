<template>
    <div class="text-xs flex justify-between text-grey-text">
        <div>
            <div v-if="updatedAt" class="block sm:flex sm:d_center">
                <div class="mr-2" >
                    Изменено
                </div>
                <div>
                    {{moment(data?.updated_at).format('DD.MM.yy [в] HH:mm')}}
                </div>
            </div>
            <div v-else class="block sm:flex sm:d_center">
                <div class="mr-2" >
                    Создано
                </div>
                <div>
                    {{moment(data?.created_at).format('DD.MM.yy [в] HH:mm')}}
                </div>
            </div>
        </div>
        <toggle-switch v-if="store.state?.user?.id === data?.owner_id" :data="data"/>
    </div>
</template>


<script setup>
import moment from "moment/moment";
import ToggleSwitch from "@/views/components/inputComponent/toggleSwitch.vue";
import {onMounted, ref} from "vue";
import store from "@/store";

const updatedAt = ref(true);
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
})

onMounted(() => {
    updatedAt.value = props.data.updated_at > props.data.created_at;
});
</script>