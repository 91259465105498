<template>
    <dashboardLayout>
        <navigation-container :navigation-data="navData"
                              :btn="{title: 'Вернуться на личную страницу', route: 'dashboard'}">

            <div class="flex justify-center mb-10">
                <div class="w-full lg:w-auto">
                    <div class="prof_container relative flex">
                        <wrap-section :is-empty=true>
                            <h2 class="text-2xl md:text-4xl ">Рейтинги</h2>
                        </wrap-section>
                    </div>
                    <div>
                        <wrap-section :is-empty="true">
                            <season-btn :current-year="currentSeason" :year="2024" @click="changeSeason(2024)"/>
                            <season-btn :current-year="currentSeason" :year="2023" class="ml-4" @click="changeSeason(2023)"/>
                            <season-btn :current-year="currentSeason" :year="2022" class="ml-4" @click="changeSeason(2022)"/>
                        </wrap-section>
                        <winners-podium :data="winnersRating" />

                        <input-search v-model="search" @input="searchHandler" :width="'w-72'" :height="'h-8'" label="" class="mt-6" />

                        <div class="overflow-hidden rounded-2xl border border-solid border-light-border mt-6">
                            <table class="min-w-full border-collapse table-auto bg-white shadow-lg">
                                <rating-header/>
                                <tbody>
                                <rating-element
                                    v-for="(element, index) in currentRating" :key="element.id"
                                    :data="element" :index="index"
                                />
                                </tbody>
                            </table>

                        </div>
                        <wrap-section :is-empty=true>
                            <button class="btn btn_white mt-8 py-2.5 px-3 !text-sm d_center w-full" @click="showAll" v-if="showRatingBtn">
                                Показать еще
                            </button>
                        </wrap-section>

                    </div>

                </div>
            </div>
        </navigation-container>
    </dashboardLayout>
</template>

<script setup>
import dashboardLayout from "@/views/layout/dashboard";
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import NavigationContainer from "@/views/layout/components/navigation-container.vue";
import InputSearch from "@/views/components/inputComponent/inputSearch.vue";
import RatingElement from "@/views/pages/dashboard/rating/ratingElement.vue";
import RatingHeader from "@/views/pages/dashboard/rating/ratingHeader.vue";
import {apiRequest, logout} from "@/utils/helpers";
import {RATING} from "@/router/api";
import {onMounted, ref} from "vue";
import WinnersPodium from "@/views/pages/dashboard/rating/winnersPodium.vue";
import useEmitter from "@/utils/useEmitter";
import PodiumItem from "@/views/components/raiting/podiumItem.vue";
import SeasonBtn from "@/views/components/raiting/seasonBtn.vue";

const currentRating = ref([]);
const serverDataRating = ref([]);
const winnersRating = ref([]);
const emitter = useEmitter();
const search = ref('');
const currentSortType = ref('score');
const currentSeason = ref(2024);
const showRatingBtn = ref(true);

const searchHandler = () => {
    if(!search.value || search.value.trim()
        .length < 2) return;
    const query = search.value.trim().toLowerCase();
    showRatingBtn.value = false;

    currentRating.value = serverDataRating.value.filter(item => {
        return item.name.toLowerCase().includes(query) ||
            item.surname.toLowerCase().includes(query);
    });
}

const showAll = () => {
    showRatingBtn.value = false;
    sortByField(currentSortType.value, 9999);
}

const sortByField = (field, qnt = 20) => {
    if(qnt === 20) {
        showRatingBtn.value = true;
    }
    currentRating.value = serverDataRating.value
        .sort((a, b) => b[field] - a[field])
        .slice(0, qnt);
};

const changeSeason = (season) => {
    console.log(season, currentSeason.value)
    if(season === currentSeason.value) return;
    fetchRating(season);
}

const fetchRating = async (season) => {
    try {
        const resp = await apiRequest(RATING + '?season=' + season + '&type=sportsman', null, 'get');
        if (resp && resp.success) {
            serverDataRating.value = resp.result;
            currentRating.value = serverDataRating.value.slice(0, 20);
            winnersRating.value = serverDataRating.value.slice(0, 3);
            currentSeason.value = season;
        }
    } catch (e) {
        console.error('Ошибка при загрузке предстоящих соревнований:', e);
    }
};

onMounted(() => {
    fetchRating(2024);
});

emitter.on('sort', value => {
    currentSortType.value = value;
    sortByField(value, 20)
})


</script>


