<template>
    <div class="flex sidebar-padding mt-7 mb-7 relative cursor-pointer" @click="navigateTo('profile')" :class="addClass">
        <icon icon-name="setup" class="absolute right-4 top-1.5" :class="{'!right-10': isMobile}"/>
        <div class="d_center mr-2">
            <img :src="getUserAvatar.url" alt="" class="w-10 h-10 rounded-full">
        </div>
        <div class="d_center">
            <div class="truncate max-w-44">
                <div class="text-main text-base-color ">
                    {{ getUserFio }}
                </div>
                <div class="text-sm text-white ">
                    {{ getUserEmail }}
                </div>
            </div>
        </div>
    </div>


</template>

<script>
import { navigateTo } from "@/utils/helpers";
import { mapGetters } from "vuex";
import Icon from "@/views/components/helpers/icon.vue";
export default {
    methods: { navigateTo },
    components: { Icon },
    computed: mapGetters(['getUserAvatar', 'getUserEmail', 'getUserFio', 'isMinHeight', 'isMobile']),

    props: {
        addClass: {
            type: String,
            default: null
        }
    },
}
</script>

<style scoped>
.mt-3 {
    margin-top: 0.75rem!important;
    margin-bottom: 0.75rem!important;
}
.menu_name {
    font-size: 14px;
}
.sidebar_icon {
    width: 25px;
}

.wrap_menu {
    margin-top: 40px;
}

.menu_item div, .menu_extra div {
    color: var(--white);
}

.menu_item {
    padding: 0 15px 0 50px;
}

.menu_extra {
    padding: 0 15px 0 50px;
}

.menu_item:hover, .active {
    background-color: var(--dark-base);
}
</style>