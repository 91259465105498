<template>
  <div class="pt-5">
      <div class="border-solid border rounded-xl border-light-border bg-light-base">
          <div class="py-3 px-5 sm:flex items-end">
              <div class="text-center md:text-left">
                  <span class=" text-grey-text">
                    {{ timerData.title }}
                  </span>
              </div>
              <div class="pt-3.5 sm:pt-0">
                  <span class="text-2xl  font-bold pl-2 leading-none" :class="timerData.styleClass">
                      {{ timerData.days }}
                  </span>
                  <span class=" text-sm pl-1.5" :class="timerData.styleClass">дней</span>

                  <span class="text-2xl  font-bold pl-2 leading-none" :class="timerData.styleClass">
                      {{ timerData.hours }}
                  </span>
                  <span class=" text-sm pl-1.5" :class="timerData.styleClass">часов</span>

                  <span class="text-2xl  font-bold pl-2 leading-none" :class="timerData.styleClass">
                      {{ timerData.minutes }}
                  </span>
                  <span class=" text-sm pl-1.5" :class="timerData.styleClass">минут</span>
              </div>
          </div>
      </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import {onMounted, ref, computed} from "vue";


const props = defineProps({
  registrationStartAt: String,
  registrationEndAt: String,
});

const registrationHasStarted = computed(() => {
  const now = new Date();
  const startAt = new Date(props.registrationStartAt);
  return now >= startAt;
});

const formatTime = (milliseconds) => {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

  return {
    days,
    hours,
    minutes,
    seconds
  };
};

const calculateTimeLeft = (endTime) => {
  const now = new Date();
  const endAt = new Date(endTime);
  const diff = endAt.getTime() - now.getTime();
  return diff > 0 ? diff : 0;
};

const registrationStartsIn = computed(() => calculateTimeLeft(props.registrationStartAt));
const registrationEndsIn = computed(() => calculateTimeLeft(props.registrationEndAt));

const timerData = computed(() => {
    if (registrationHasStarted.value) {
      const { days, hours, minutes } = formatTime(registrationEndsIn.value);
      return {
        styleClass: 'text-danger-middle',
        title: 'До конца регистрации:',
        days,
        hours,
        minutes
      };
    } else {
      const { days, hours, minutes } = formatTime(registrationStartsIn.value);
      return {
        styleClass: 'text-green-message-text',
        title: 'До начала регистрации:',
        days,
        hours,
        minutes
      };
    }
  }
);


</script>