<template>
    <div class="w-full">
        <div class="w-full bg-yellow-message rounded-lg border">
            <div class="text-center text-black text-xs font-medium leading-9 d_center">
                Оплата&nbsp;наличными
                <svg class="ml-2" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.891 2.75 2.75 6.891 2.75 12c0 5.108 4.141 9.25 9.25 9.25s9.25-4.142 9.25-9.25S17.108 2.75 12 2.75zM1.25 12C1.25 6.063 6.063 1.25 12 1.25S22.75 6.063 22.75 12 17.937 22.75 12 22.75 1.25 17.937 1.25 12zm9.911-5.75H13.5c1.795 0 3.25 1.455 3.25 3.25s-1.455 3.25-3.25 3.25H10.75v.5H12a.75.75 0 0 1 0 1.5h-1.25v2.25a.75.75 0 0 1-1.5 0V14.75H9a.75.75 0 0 1 0-1.5h.25v-.5H9a.75.75 0 0 1 0-1.5h.25V8.2c-.001-.034-.001-.06.022-.335a1.75 1.75 0 0 1 1.454-1.472 2.5 2.5 0 0 1 .435-.04zm-.411 5h2.75c.966 0 1.75-.784 1.75-1.75s-.784-1.75-1.75-1.75h-2.3c-.097 0-.154.001-.198.002a.75.75 0 0 0-.198.002.75.75 0 0 0-.123.198c-.001.003-.002.015-.003.042a5.14 5.14 0 0 0-.001.406V11.25z" fill="#1C274C"/></svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from "vue";
import Icon from "@/views/components/helpers/icon.vue";

const openModal = ref(false);

</script>

<style scoped>

</style>