<template>

    <div class="divide-y divide-light-border divide-solid mt-5 mb-5" v-if="data.length">
        <div v-for="el in data" :key="el.id" class='flex justify-between pt-3 pb-3'>
            <competition-docs-item :data="el" :event-uuid="route.params.id"/>
        </div>
    </div>


    <div class="text-base font-bold">Загрузите документы для ознакомления</div>
    <div class="text-base mb-2">В форматах PDF</div>
    <file-upload  message="Не удалось загрузить файл. Загрузите .pdf файл" :return-url="true"
                  upload-type="eventDoc" :event-id="route.params.id" :file-upload-number="5"
                  accept-doc="application/pdf"
    />

</template>

<script setup>

import {onMounted, ref} from "vue";
import FileUpload from "@/views/components/dashboard/fileUpload.vue";
import useEmitter from "@/utils/useEmitter";
import {apiRequest} from "@/utils/helpers";
import {EVENT_DOC} from "@/router/api";
import CompetitionDocsItem from "@/views/components/createCompetition/competitionDocsItem.vue";
import {useRoute} from "vue-router";

const isLoading = ref(false);
const data = ref([]);
const emitter = useEmitter();
const route = useRoute();


const getEventDocs = () => {
    apiRequest(EVENT_DOC + '?event_uuid=' + route.params.id, [], 'get')
        .catch(e => {
            console.error("Ошибка", e);
        })
        .then(async resp => {
            if (resp && resp.success && resp.result) {
                data.value = resp.result;
            }
        })
}

onMounted(() => {
    getEventDocs();
});


emitter.on("returnUrl", value => {
    if (value.success && value.uploadType === 'eventDoc') {
        getEventDocs();
    }
});

emitter.on("deleteEventDoc", value => {
    if (value.success && value.id) {
        data.value = data.value.filter(el => el.id !== value.id);
    }
});

</script>