<template>
    <div class="relative md:hidden ">
        <div class="w-full wrap_mob_header flex justify-between">
            <div class="flex items-center">
                <img src="/logo_fed_old.svg" alt="" class="logo_mob_header">
            </div>
            <div class="flex items-center" @click="isOpen = !isOpen">
                <div class="w-9">
                    <icon icon-name="sandwich" class="cursor-pointer" v-if="!isOpen"/>
                    <icon icon-name="close" class="cursor-pointer" v-if="isOpen"/>
                </div>
            </div>
        </div>
        <div class="mob_wrap_menu fixed w-full z-10 h-screen flex justify-between flex-col" v-if="isOpen">
            <div>
                <div class="w-full sidebar_line"></div>
                <sidebar-avatar add-class="sidebar-padding-mob" :is-mobile="true" />
                <sidebar-menu :is-mobile="true" add-class="sidebar-padding-mob" />
            </div>
            <sidebar-logout class="mb-20" add-class="sidebar-padding-mob"/>
        </div>
    </div>
</template>

<script setup>
import Icon from "@/views/components/helpers/icon.vue";
import store from "@/store";
import SidebarMenu from "@/views/layout/components/sidebar-menu.vue";
import {ref} from "vue";
import SidebarAvatar from "@/views/layout/components/sidebar-avatar.vue";
import SidebarLogout from "@/views/layout/components/sidebar-logout.vue";

let isOpen = ref(false);

</script>

<style scoped>
.mob_wrap_menu {
    margin: 60px 0;
    background: radial-gradient(165.76% 68.43% at 21.11% 104.81%, var(--base-color) 0%, var(--grad-dark) 100%);
    transform: translateY(-150%);
    animation: ani 0.5s forwards;
}
@keyframes ani {
    0% {transform: translateY(-150%);}
    100% {transform: translateY(0);}
}

.wrap_mob_header {
    z-index: 10;
    padding: 0 30px;
    position: fixed;
    height: 60px;
    background-color: var(--grad-dark);
}
.logo_mob_header {
    height: 50px;
}

</style>