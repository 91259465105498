<template>
    <div class="d_center">
        <div class="flex justify-between pt-5 ml-5 mr-5 w-1100">
            <div @click="navigateTo('home')" class="logo h-10 md:h-14" ><img src="/logo_fed.svg" alt="" class="w-full h-full"></div>

            <div v-if="store.state.isAuthenticated" class="d_center w-36 hover:underline cursor-pointer text-base-color hover:text-dark-base"
                 @click="navigateTo('dashboard')">
                <img :src="store.state.user.avatar" alt="" class="w-10 h-10 rounded-full mr-2">
                <span class="text-inherit">
                    {{ store.state.user.name }} {{ store.state.user.surname }}
                </span>
            </div>

            <button v-else class="btn btn_white header_auth d_center w-32 h-10" @click="navigateTo('login')">
                <svg width="15" height="18" viewBox="0 0 15 18"  xmlns="http://www.w3.org/2000/svg" class="mr-5">
                    <path d="M7.5 8C8.29113 8 9.06448 7.76541 9.72228 7.32588C10.3801 6.88635 10.8928 6.26164 11.1955 5.53074C11.4983 4.79983 11.5775 3.99556 11.4231 3.21964C11.2688 2.44372 10.8878 1.73098 10.3284 1.17157C9.76902 0.612164 9.05628 0.231202 8.28036 0.0768607C7.50444 -0.0774802 6.70017 0.00173312 5.96927 0.304484C5.23836 0.607234 4.61365 1.11992 4.17412 1.77772C3.7346 2.43552 3.5 3.20888 3.5 4C3.5 5.06087 3.92143 6.07828 4.67157 6.82843C5.42172 7.57857 6.43913 8 7.5 8ZM13.5 18C13.7652 18 14.0196 17.8946 14.2071 17.7071C14.3946 17.5196 14.5 17.2652 14.5 17C14.5 15.1435 13.7625 13.363 12.4497 12.0503C11.137 10.7375 9.35652 10 7.5 10C5.64348 10 3.86301 10.7375 2.55025 12.0503C1.2375 13.363 0.5 15.1435 0.5 17C0.5 17.2652 0.605357 17.5196 0.792893 17.7071C0.98043 17.8946 1.23478 18 1.5 18H13.5Z" class="svg"/>
                </svg>
                Войти
            </button>
        </div>
    </div>
    <slot />

    <div class="h-20" :class="{'!h-10': store.state.isMinHeight}"></div>
</template>

<script setup>
import {navigateTo, personalDataUpdate} from "@/utils/helpers";
import store from "@/store";
import {onMounted} from "vue";

onMounted(async () => {
    await personalDataUpdate();
});
</script>
<style scoped>

</style>
