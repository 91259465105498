<template>
    <wrap-section class="mt-5">
        <div class="flex justify-between">
            <div class="flex">
                <section-header header="Мои спортсмены" />
                <div class="text-center text-lg flex place-content-center sm:place-content-start font-bold text-icon-base ml-5">{{store.state.sportsman.length}}</div>
            </div>
            <div class="cursor-pointer text-sm text-base-color hidden sm:block mt-1" @click="navigateTo('sportsmans')">
                Посмотреть всех
            </div>
        </div>
        <div class="mt-5 flex items-start gap-2 flex-wrap justify-between">
            <div class="items-center flex flex-col cursor-pointer h-32 w-32" v-for="sportsman in getMyStoreSportsman(5)">
                <div class="w-16 h-16">
                    <img class="rounded-full w-full h-full" :src="getAvatar(sportsman)"  alt=""/>
                </div>
                <div class="text-center text-black text-xs font-normal mt-3">
                    {{ sportsman.name + ' ' + sportsman.surname }}
                </div>
            </div>
            <div class="d_center flex-col cursor-pointer h-32 w-32 bg-light-base rounded-2xl border border-solid border-light-border"
                 @click="navigateTo('addNewSportsman')"
                 v-if="!store.state.sportsman.length"
            >
                <icon icon-name="plus" />
                <div class="text-center text-base-color">
                    Добавить спортсменов
                </div>
            </div>
        </div>
        <div class="text-sm text-base-color sm:hidden mt-1" @click="navigateTo('sportsmans')">
            Посмотреть всех
        </div>

    </wrap-section>

</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import Icon from "@/views/components/helpers/icon.vue";
import SectionHeader from "@/views/components/dashboard/sectionHeader.vue";
import {getAvatar, getMyStoreSportsman, getSportsman, navigateTo} from "@/utils/helpers";
import {onMounted} from "vue";
import store from "@/store";

onMounted(async () => {
    await getSportsman();
});

</script>

<style scoped>

</style>

