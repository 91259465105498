<template>

    <text-area-custom label="Правила" v-model="fd.conditions_info" class="mt-4 mob_margin" labelClass="pass-update"/>

    <br> <span class="text-dark-base text-xl font-bold mt-5">Стоимость</span> <br>

    <div v-for="(el, index) in fd.prices" :id="index">
        <competition-prices-item :data="el" :index="index" :priceItemCategory="priceItemCategory"/>
    </div>

    <alerts alertType="priceItem"/>

    <div class="w-full flex mt-3 text-base-color cursor-pointer justify-end"
         @click="addExtraPrice">
        <div class="d_center">
            <icon icon-name="plus" class="mr-3"/>
        </div>
        Добавить еще цену
    </div>

    <alerts alertType="priceItem2"/>

    <button class="btn mt-8 btn_blue w-full sm:w-64 " @click="saveCompetitionCondition">
        Сохранить
    </button>


</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import useEmitter from "@/utils/useEmitter";
import Alerts from "@/views/components/helpers/alerts.vue";
import TextAreaCustom from "@/views/components/inputComponent/textAreaCustom.vue";
import CompetitionPricesItem from "@/views/components/createCompetition/competitionPricesItem.vue";
import Icon from "@/views/components/helpers/icon.vue";
import {apiRequest, getDirectionCategories} from "@/utils/helpers";
import {EVENTS} from "@/router/api";
import {useRoute} from "vue-router";
import store from "@/store";

const props = defineProps({
    data: {
        type: Object,
        default: {},
    },
})
const route = useRoute();
const emitter = useEmitter();
const isLoading = ref(false);
const priceItemCategory = ref([]);
const fd = ref({
    type: 'competitionCondition',
    conditions_info: '',
    offer: '',
    prices: [{price: 0, title: null, direction_categories: null}],
});

emitter.on('competitionPrices', value => {
    if (value[1]) {
        fd.value.prices[value[0]] = value[1];
    }
    updatePriceCategory();
})

const validation = () => {
    filterPrices();
    let base = 'Проверьте корректность ввода поля '
    if (fd.value.prices[0] < 1) return emitter.emit("alert", {alertType: 'priceItem2', message: base + 'Цена'});
    return true;
}

const filterPrices = () => {
    fd.value.prices = fd.value.prices.filter(priceObj => priceObj.price > 0);
};

const saveCompetitionCondition = () => {
    if (validation()) {
        isLoading.value = true;

        apiRequest(EVENTS + '/' + route.params.id, fd.value, 'put')
            .then(resp => {
                if (resp && resp.success && resp.message) {
                    emitter.emit("success", {alertType: 'priceItem2', message: resp.message});
                }
            })
            .catch(e => {
                console.log('error', e)
            })
            .finally(() => {
                isLoading.value = false;
            });

    }
}

const updatePriceCategory = () => {
    const priceIds = fd.value.prices.map(item =>
        item.direction_category_id || item.direction_category?.id
    ).filter(Boolean);
    priceItemCategory.value = store.getters.getDirectionCategories.filter(category => !priceIds.includes(category.id));
}

const addExtraPrice = () => {
    if (fd.value.prices[fd.value.prices.length - 1]?.price < 1) {
        emitter.emit("alert", {
            alertType: 'priceItem',
            message: "Для добавления дополнительной цены, заполните текущее поле",
            isSuccess: 'false'
        });
    } else {
        fd.value.prices.push({price: 0, title: ''})
    }
    updatePriceCategory();
}



watch(() => props.data, (newValue) => {
    if (newValue && typeof newValue === 'object' && Object.keys(newValue).length > 0
        && newValue.price && newValue.price.length) {
        fd.value.prices = newValue.price;
        updatePriceCategory();
    }
    if (newValue.conditions_info) {
        fd.value.conditions_info = newValue.conditions_info;
    }
}, {deep: true, immediate: true});

watch(() => fd.value.prices, () => {updatePriceCategory();},
    {deep: true}
);


onMounted(async () => {
    await getDirectionCategories();
    priceItemCategory.value = store.getters.getDirectionCategories;
});


</script>