<template>
    <wrap-section>
        <span class="text-dark-base text-xl font-bold">Пароль</span><br><br>

        <input-custom label="Старый пароль" type="password" v-model="fd.oldPass" class="mt-4 mob_margin" labelClass="new-pass-update"/>
        <input-custom label="Новый пароль" type="password" v-model="fd.newPass" class="mt-4 mob_margin" labelClass="new-pass-update"/>
        <input-custom label="Повторите пароль" type="password" v-model="fd.newPassRepeat" class="mt-4 mob_margin" labelClass="new-pass-update"/>
        <alerts alertType="passUpdate" />
        <button class="btn mt-8 btn_blue w-full sm:w-64" @click="changePassword">
            Сохранить пароль
        </button>
    </wrap-section>
</template>

<script setup>
import WrapSection from "@/views/components/helpers/wrapSection.vue";
import InputCustom from "@/views/components/inputComponent/inputCustom.vue";
import {ref} from "vue";
import useEmitter from "@/utils/useEmitter";
import Alerts from "@/views/components/helpers/alerts.vue";
import {apiRequest} from "@/utils/helpers";
import {PASSWORD} from "@/router/api";

const emitter = useEmitter();
const fd = ref({
    oldPass: '',
    newPass: '',
    newPassRepeat: ''
});

const validation = () => {
    if(fd.value.newPass !== fd.value.newPassRepeat) {
        return emitter.emit("alert", {alertType: 'passUpdate', message: 'Новый и старый пароль не совпадают'});
    }
    if(fd.value.newPass.length < 8 || fd.value.newPassRepeat.length < 8) return emitter.emit("alert", {alertType: 'passUpdate', message: 'Длинна пароля должна быть не менее 8 символов'});
    return true;
}
const changePassword = async () => {
    if (validation()) {
        await apiRequest(PASSWORD, fd.value, 'post')
            .catch(e => {
                console.log('error', e)
            })
            .then(resp => {
                if (resp.error) {
                    return emitter.emit("alert", {alertType: 'passUpdate', message: resp.message});
                }
                if (resp.success) {
                    fd.value.newPass = '';
                    fd.value.oldPass = '';
                    fd.value.newPassRepeat = '';

                    return emitter.emit("success", {alertType: 'passUpdate', message: resp.message});
                }
            })

    }
}


</script>

<style scoped>


</style>
