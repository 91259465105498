<template>

<input-search v-model="search" @input="searchHandler" />

    <div class="divide-y divide-light-border divide-solid mt-7">
        <sportsman v-for="el in data" :sportsmanItem="el" :key="el.uuid" v-if="data.length"/>
        <div v-else>
            В ваших организациях нет спортсменов
        </div>
    </div>

    <pagination :meta="meta" v-model="currentPage"/>

</template>

<script setup>
import {apiRequest} from "@/utils/helpers";
import sportsman from "./sportsman.vue";
import InputSearch from "@/views/components/inputComponent/inputSearch.vue";
import {onMounted, ref, watch} from "vue";
import Pagination from "@/views/components/helpers/pagination.vue";
import {SPORTSMANS, ALL_SPORTSMANS_SEARCH} from "@/router/api";

const meta = ref({});
const input = ref("");
const data = ref([]);
const currentPage = ref(1);
const search = ref('');

const searchHandler = () => {
  // Сбрасываем текущую страницу на 1 при изменении поиска
  currentPage.value = 1;
  loadSportsmans();
}

const loadSportsmans = async (page = 1) => {
    try {
        // Создаем объект параметров
        let params = { page };
        if (search.value !== '') {
            params.all_sportsmans_search = search.value;
        }

        // Делаем запрос к API с параметрами
        const response = await apiRequest(
            search.value !== '' ? ALL_SPORTSMANS_SEARCH : SPORTSMANS, 
            { params },
            'get'
        );

        // Проверяем ответ и обновляем данные
        if (response && response.success) {
            data.value = response.result.data;
            meta.value = response.result.pagination;
        }
    } catch (e) {
        console.error('Ошибка при загрузке данных о спортсменах:', e);
    }

};


watch(currentPage, (newPage) => {
    loadSportsmans(newPage);
});

onMounted(async() => {
    await loadSportsmans(currentPage.value);
});

defineExpose({
    loadSportsmans,
});

</script>
